import React, { useState, useEffect } from "react";
import DaysToEndOfMonth from "../common/DaysToEndOfMonth";
import { useNavigate } from "react-router-dom";
import EldLogo from "../../assets/logos/Logo-ELD-02 transparent.png";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Menubar } from "primereact/menubar";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Message } from "primereact/message";
import { ProgressSpinner } from "primereact/progressspinner";
import _ from "lodash";
import ObjectID from "bson-objectid";
import { useSelector, useDispatch } from "react-redux";
import {
  showEldDialog,
  showIntDialog,
  showCtyDialog,
  showLogDialog,
  showOthDialog,
} from "../../redux/actions/dialogActions.js";
import {
  clearClient,
  clearComment,
  clearCty,
  clearDate,
  clearEsp,
  clearHour,
  clearModType,
  clearModule,
  clearStage,
  clearTask,
  setClient,
  setComment,
  setCty,
  setDate,
  setEsp,
  setHour,
  setModType,
  setModule,
  setStage,
  setTask,
} from "../../redux/actions/logActions";
import { dictionary } from "../../dictionary";
import { fetchInitialPosts } from "../../redux/thunk/thunk";

const UserLogsTable = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contentProps = props.contentProps;
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const userPosts = useSelector((state) => state.posts.data);
  const [loading, setLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [connectionError, setConnectionError] = useState(null);
  const [forbiddenError, setForbiddenError] = useState(null);
  const username = sessionStorage.getItem("userName");
  const personType = sessionStorage.getItem("user")
    ? JSON.parse(sessionStorage.getItem("user")).person
    : null;
  const [tabKeys, setTabKeys] = useState(null);
  const [expandedRows, setExpandedRows] = useState(null);
  const [showClientList, setShowClientList] = useState(false);
  const sliderInfo = [
    dictionary.slider_info1,
    dictionary.slider_info2,
    dictionary.slider_info3,
    dictionary.slider_info4,
    dictionary.slider_info5,
    dictionary.slide_info6,
  ];
  const [currentInfo, setCurrentInfo] = useState(0);
  const [key, setKey] = useState(0);
  const start = <DaysToEndOfMonth />;
  const end = (
    <>
      <Button
        icon="pi pi-info-circle"
        label="Documentation"
        onClick={() => {
          navigate("/documentation");
        }}
      />
      <img alt="logo" src={EldLogo} className="mr-2"></img>
    </>
  );

  const onPage = (rowData) => {
    setCurrentPage(rowData.first / recordsPerPage);
  };

  useEffect(() => {
    const fetchTotalRecords = async () => {
      try {
        const response = await fetch(
          `https://eld-timer-backend-service.api.prod.sit.sys.odj.cloud/posts/${username}/count`, {
            method: 'GET',
            mode: 'cors',
            credentials: "include",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
  
        if (response.status === 403) {
          throw new Error('Forbidden');
        }
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const count = await response.json();
        if (!count.length) {
          setTotalRecords(0);
        } else {
          setTotalRecords(count[0].count);
        }
      } catch (error) {
        if (error.message === 'Failed to fetch') {
          console.error('Connection refused. Please check if the server is running.');
          setConnectionError('Connection refused. Please check if the server is running.');
        } else if (error.message === 'Forbidden') {
          console.error('Access denied. You do not have permission to access this resource.');
          setForbiddenError('Connection refused. Please check if the server is running.');
        } else {
          console.error('An error occurred:', error.message);
        }
      }
    };
  
    fetchTotalRecords();
  }, []);

  useEffect(() => {
    if (contentProps.eldData !== null) {
      setTabKeys(contentProps.eldData[0]?.perso);
    }
  }, [contentProps.eldData]);

  const rowClass = (rowData) => {
    if (personType === "normal") {
      return rowData.hours >= 8 ? "green-row" : "red-row";
    } else {
      return rowData.hours >= 7 ? "green-row" : "red-row";
    }
  };

  const issueClass = (rowData) => {
    switch (rowData.module) {
      case "Urlop":
        return "cir_0";
      case "Szkolenia":
        return "cir_1";
      case "INT_Spotkania":
        return "cir_2";
      case "Spotkania":
        return "cir_2";
      case "Nieobecność":
        return "cir_3";
      case "Wcześniejsze wyjście":
        return "cir_4";
      case "Organizacja pracy":
        return "cir_5";
      case "Wolne moce":
        return "cir_6";
      default:
        break;
    }
    switch (rowData.wpl) {
      case "Biuro":
        return "cir_7";
      case "PZO/PZH":
        return "cir_8";
      default:
        break;
    }
  };

  const handleClientChoice = (client) => {
    dispatch(setClient(client));
    contentProps.setPostEditing(true);
    handleOpenDialog(client);
    setShowClientList(false);
  };

  const handleOpenDialog = (cli) => {
    let client = cli.toLowerCase();
    switch (client) {
      case "eld":
        dispatch(setClient("eld"));
        dispatch(showEldDialog());
        break;
      case "int":
        dispatch(setClient("int"));
        dispatch(showIntDialog());
        break;
      case "country":
        dispatch(setClient("country"));
        dispatch(showCtyDialog());
        break;
      case "logistics":
        dispatch(setClient("logistics"));
        dispatch(showLogDialog());
        break;
      case "other":
        dispatch(setClient("other"));
        dispatch(showOthDialog());
        break;
      default:
        return null;
    }
  };

  const handleRowEdit = (rowData) => {
    if (rowData.client.toLowerCase() === "") {
      setShowClientList(true);
      let parts = rowData.date.split("-");
      const yyy = parseInt(parts[0]);
      const mm = parseInt(parts[1]) - 1;
      const dd = parseInt(parts[2]);
      const date = new Date(yyy, mm, dd).toDateString();
      rowData._id === ""
        ? contentProps.setEditId(null)
        : contentProps.setEditId(rowData._id);
      rowData.date === "" ? dispatch(clearDate()) : dispatch(setDate(date));
      rowData.module === ""
        ? dispatch(clearModule())
        : dispatch(setModule(rowData.module));
      rowData.client === ""
        ? dispatch(clearClient())
        : dispatch(setClient(rowData.client));
      rowData.country === ""
        ? dispatch(clearCty())
        : dispatch(setCty(rowData.country));
      rowData.module_type === ""
        ? dispatch(clearModType())
        : dispatch(setModType(rowData.module_type));
      rowData.stage === ""
        ? dispatch(clearStage())
        : dispatch(setStage(rowData.stage));
      rowData.hours === ""
        ? dispatch(clearHour())
        : dispatch(setHour(rowData.hours));
      rowData.comment === ""
        ? dispatch(clearComment())
        : dispatch(setComment(rowData.comment));
      rowData.task === ""
        ? dispatch(clearTask())
        : dispatch(setTask(rowData.task));
      rowData.esp === "" ? dispatch(clearEsp()) : dispatch(setEsp(rowData.esp));
    } else {
      contentProps.setPostEditing(true);
      let parts = rowData.date.split("-");
      const yyy = parseInt(parts[0]);
      const mm = parseInt(parts[1]) - 1;
      const dd = parseInt(parts[2]);
      const date = new Date(yyy, mm, dd).toDateString();
      handleOpenDialog(rowData.client);
      rowData._id === ""
        ? contentProps.setEditId(null)
        : contentProps.setEditId(rowData._id);
      rowData.date === "" ? dispatch(clearDate()) : dispatch(setDate(date));
      rowData.module === ""
        ? dispatch(clearModule())
        : dispatch(setModule(rowData.module));
      rowData.client === ""
        ? dispatch(clearClient())
        : dispatch(setClient(rowData.client));
      rowData.country === ""
        ? dispatch(clearCty())
        : dispatch(setCty(rowData.country));
      rowData.module_type === ""
        ? dispatch(clearModType())
        : dispatch(setModType(rowData.module_type));
      rowData.stage === ""
        ? dispatch(clearStage())
        : dispatch(setStage(rowData.stage));
      rowData.hours === ""
        ? dispatch(clearHour())
        : dispatch(setHour(rowData.hours));
      rowData.comment === ""
        ? dispatch(clearComment())
        : dispatch(setComment(rowData.comment));
      rowData.task === ""
        ? dispatch(clearTask())
        : dispatch(setTask(rowData.task));
      rowData.esp === "" ? dispatch(clearEsp()) : dispatch(setEsp(rowData.esp));
    }
  };

  const onRowCopy = (rowData) => {
    const log = {
      id: ObjectID().toHexString(),
      user: rowData.user,
      client: rowData.client,
      esp: rowData.esp,
      country: rowData.country,
      module_type: rowData.module_type,
      module: rowData.module,
      stage: rowData.stage,
      task: rowData.task,
      hours: rowData.hours,
      wpl: rowData.wpl,
      workingh: rowData.workingh,
      comment: rowData.comment,
      date: rowData.date,
      cw: rowData.cw,
    };
    fetch("https://eld-timer-backend-service.api.prod.sit.sys.odj.cloud/posts", {
      method: "POST",
      mode: 'cors',
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(log),
    }).then((response) => {
      if (response.ok) {
        dispatch(fetchInitialPosts(contentProps.loggedUser));
      }
    });
  };

  const expandAll = () => {
    let _expandedRows = [];

    userPosts.forEach((p) => {
      _expandedRows.push(p);
    });
    setExpandedRows(_expandedRows);
  };

  const collapseAll = () => {
    setExpandedRows(null);
  };

  const header = (
    <div className="header-btn-wrapper">
      <Button
        icon="pi pi-plus"
        label={dictionary.user_logs_table_btn_label_expand}
        onClick={expandAll}
      />
      <Button
        icon="pi pi-minus"
        label={dictionary.user_logs_table_btn_label_collapse}
        onClick={collapseAll}
      />
    </div>
  );

  const rowExpansionTemplate = (rowData) => {
    return (
      <DataTable value={rowData.entries} stripedRows rowClassName={issueClass}>
        <Column field="user" header="User"></Column>
        <Column field="client" header="Client"></Column>
        <Column field="esp" header="Esp"></Column>
        <Column field="country" header="Country"></Column>
        <Column field="module_type" header="Module type"></Column>
        <Column field="module" header="Module"></Column>
        <Column field="stage" header="Stage"></Column>
        <Column field="task" header="Task"></Column>
        <Column field="hours" header="Hours"></Column>
        <Column field="comment" header="Comment"></Column>
        <Column
          body={(rowData) => (
            <div className="row-btn-wrapper">
              <Button
                className="copy-button"
                icon="pi pi-copy"
                onClick={() => onRowCopy(rowData)}
              />
              <Button
                className="edit-button"
                icon="pi pi-pencil"
                onClick={() => handleRowEdit(rowData)}
              />
              <Button
                className="delete-button"
                icon="pi pi-times"
                onClick={() => onRowDelete(rowData._id)}
              />
            </div>
          )}
        ></Column>
      </DataTable>
    );
  };

  const onRowDelete = (itemId) => {
    fetch(`https://eld-timer-backend-service.api.prod.sit.sys.odj.cloud/posts/${itemId}`, {
      method: "DELETE",
      mode: 'cors',
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          dispatch(fetchInitialPosts(contentProps.loggedUser));
        } else {
          console.error(
            "An error occurred while deleting an item from the database!"
          );
          return;
        }
      })
      .catch((error) => console.error("Error: ", error));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentInfo((currentInfo + 1) % sliderInfo.length);
      setKey((prevKey) => prevKey + 1);
    }, 10000);

    return () => clearInterval(interval);
  }, [currentInfo, sliderInfo]);

  useEffect(() => {
    if (userPosts) {
      setLoading(false);
      setDataLoaded(true);
    }
  }, [userPosts]);

  return (
    <div className="post-table">
      <Menubar start={start} end={end} />
      <div className="slider-info">
        {" "}
        <i className="pi pi-info-circle"></i>
        <p key={key} className="animation-fade-in">
          {sliderInfo[currentInfo]}
        </p>
      </div>
      <div className="tab-keys-wrapper">
        <h2>{dictionary.user_logs_table_tabkeys_header}</h2>
        <ul>
          {tabKeys
            ? tabKeys.map((obj, i) => {
                return (
                  <li key={i}>
                    <div className={"key-circle cir_" + i}></div>
                    <span>{obj.name}</span>
                  </li>
                );
              })
            : null}
        </ul>
      </div>
      <Dialog
        header={dictionary.user_logs_table_dialog_client}
        visible={showClientList}
        style={{ width: "50vw" }}
        onHide={() => {
          setShowClientList(false);
        }}
      >
        <div className="post-table-dialog-btn-wrapper">
          <Button
            icon="pi pi-external-link"
            label={dictionary.user_logs_table_dialog_client_btn_label1}
            onClick={() => handleClientChoice("ELD")}
          />
          <Button
            icon="pi pi-external-link"
            label={dictionary.user_logs_table_dialog_client_btn_label2}
            onClick={() => handleClientChoice("INT")}
          />
          <Button
            icon="pi pi-external-link"
            label={dictionary.user_logs_table_dialog_client_btn_label3}
            onClick={() => handleClientChoice("COUNTRY")}
          />
          <Button
            icon="pi pi-external-link"
            label={dictionary.user_logs_table_dialog_client_btn_label4}
            onClick={() => handleClientChoice("LOGISTICS")}
          />
          <Button
            icon="pi pi-external-link"
            label={dictionary.user_logs_table_dialog_client_btn_label5}
            onClick={() => handleClientChoice("OTHER")}
          />
        </div>
      </Dialog>
      {loading ?  (
        <div className="loading-wrapper">
          <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="2" animationDuration="3s" />
        </div>
      ) : dataLoaded && userPosts.length > 0 ? (
        <DataTable
          value={userPosts}
          totalRecords={totalRecords}
          onPage={onPage}
          expandedRows={expandedRows}
          onRowToggle={(e) => {
            setExpandedRows(e.data);
          }}
          rowClassName={rowClass}
          rowExpansionTemplate={rowExpansionTemplate}
          stripedRows
          paginator
          header={header}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} logs"
          rows={recordsPerPage}
          first={currentPage * recordsPerPage}
          removableSort
        >
          <Column expander style={{ width: "5rem" }} />
          <Column field="date" header="Date"></Column>
          <Column field="workingh" header="Work hours"></Column>
          <Column field="wpl" header="Work place"></Column>
          <Column field="hours" header="Hours"></Column>
          <Column field="cw" header="CW"></Column>
        </DataTable>
      ) : ( 
        <Message severity="info" text={dictionary.user_logs_table_no_posts} />
      )}
      {connectionError ? <Message severity="error" text={connectionError} /> : null}
      {forbiddenError ? <Message severity="warn" text={forbiddenError} /> : null}
    </div>
  );
};

export default UserLogsTable;
