import React from "react";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";

const TeamsPercentData = (props) => {
  const calcProps = props.calcProps;
  const styles = props.calcStyles;
  if (!calcProps.filteredPostsByMonth.length) {
    return;
  }
  const monthName = calcProps.filteredPostsByMonth[calcProps.chartDateIndex].month;
  const sumAllCountryHoursByMonth = (index) => {
    let temp = [];
    calcProps.filteredPostsByMonth[index].posts.forEach((post) => {
      if (post.client === "COUNTRY" && post.country !== "" && post.country !== null) {
        temp.push(post.hours);
      }
    });
    return Math.floor(temp.reduce((acc, curr) => acc + curr, 0));
  };

  const sumAllIntHoursByMonth = (index) => {
    let temp = [];
    calcProps.filteredPostsByMonth[index].posts.forEach((post) => {
      if (post.client === "INT") {
        temp.push(post.hours);
      }
    });
    return Math.floor(temp.reduce((acc, curr) => acc + curr, 0));
  };

  const sumAllLogisticsHoursByMonth = (index) => {
    let temp = [];
    calcProps.filteredPostsByMonth[index].posts.forEach((post) => {
      if (post.client === "LOGISTIC") {
        temp.push(post.hours);
      }
    });
    return Math.floor(temp.reduce((acc, curr) => acc + curr, 0));
  };

  const sumAllVacationsHoursByMonth = (index) => {
    let temp = [];
    calcProps.filteredPostsByMonth[index].posts.forEach((post) => {
      if (post.module === "Urlop") {
        temp.push(post.hours);
      }
    });
    return Math.floor(temp.reduce((acc, curr) => acc + curr, 0));
  };

  const sumAllAbsenceHoursByMonth = (index) => {
    let temp = [];
    calcProps.filteredPostsByMonth[index].posts.forEach((post) => {
      if (post.module === "Nieobecność") {
        temp.push(post.hours);
      }
    });
    return Math.floor(temp.reduce((acc, curr) => acc + curr, 0));
  };

  const sumAllEldHoursByMonth = (index) => {
    let temp = [];
    calcProps.filteredPostsByMonth[index].posts.forEach((post) => {
      if (post.client === "ELD") {
        temp.push(post.hours);
      }
    });
    return Math.floor(temp.reduce((acc, curr) => acc + curr, 0));
  };

  const sumAllWOrgHoursByMonth = (index) => {
    let temp = [];
    calcProps.filteredPostsByMonth[index].posts.forEach((post) => {
      if (post.module === "Organizacja pracy") {
        temp.push(post.hours);
      }
    });
    return Math.floor(temp.reduce((acc, curr) => acc + curr, 0));
  };

  const sumAllMeetingsHoursByMonth = (index) => {
    let temp = [];
    calcProps.filteredPostsByMonth[index].posts.forEach((post) => {
      if (post.module === "Spotkania") {
        temp.push(post.hours);
      }
    });
    return Math.floor(temp.reduce((acc, curr) => acc + curr, 0));
  };

  const chartLaz =
    sumAllCountryHoursByMonth(calcProps.chartDateIndex) +
    sumAllIntHoursByMonth(calcProps.chartDateIndex) +
    sumAllLogisticsHoursByMonth(calcProps.chartDateIndex) +
    sumAllVacationsHoursByMonth(calcProps.chartDateIndex) +
    sumAllAbsenceHoursByMonth(calcProps.chartDateIndex);
  const chartEld = sumAllEldHoursByMonth(calcProps.chartDateIndex);
  const chartWorg = sumAllWOrgHoursByMonth(calcProps.chartDateIndex);
  const chartMeet = sumAllMeetingsHoursByMonth(calcProps.chartDateIndex);

  const chartDataPercent = {
    labels: ["LAZ summary", "ELD technology", "Work organization", "Meetings"],
    datasets: [
      {
        data: [chartLaz, chartEld, chartWorg, chartMeet],
        backgroundColor: ["#4472C4", "#385723", "#548235", "#A9D18E"],
      },
    ],
  };

  const handlePercentage = (data) => {
    const chartData = data.datasets[0].data;
    const reducer = (acc, curr) => acc + curr;
    const total = chartData.reduce(reducer);
    const percentageArr = [];
    chartData.forEach((val) => {
      percentageArr.push(((val * 100) / total).toFixed(2) + "%");
    });
    return percentageArr;
  };

  const chartOptionsPercent = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: monthName + " " + new Date().getFullYear() +" netto + 10% management",
        position: "top",
      },
    },
  };

  const legendItems = chartDataPercent.labels.map((label, index) => (
    <li key={index}>
      <div style={{ backgroundColor: chartDataPercent.datasets[0].backgroundColor[index] }}></div>
      {label + " " + "(" + handlePercentage(chartDataPercent)[index] + ")"}
    </li>
  ));

  const itemsPerColumn = Math.ceil(chartDataPercent.labels.length / 2);
  const colItems1 = legendItems.slice(0, itemsPerColumn);
  const colItems2 = legendItems.slice(itemsPerColumn);

  return (
    <>
      <tr>
        <th>10%</th>
      </tr>
      <tr>
        <th colSpan="2" className="default" style={styles.default}>
          LAZ Summary
        </th>
        {calcProps.wd.map((_, index) => {
          const laz = sumAllCountryHoursByMonth(index) + sumAllIntHoursByMonth(index) + sumAllLogisticsHoursByMonth(index);
          const vacations = sumAllVacationsHoursByMonth(index);
          const absence = sumAllAbsenceHoursByMonth(index);
          const res = Math.round((laz + vacations + absence) * 1.1).toFixed(1);
          return (
            <td key={index} className="defaultBold" style={styles.defaultBold}>
              {res.toString().replace(".", ",")}
            </td>
          );
        })}
        <td className="green" style={styles.green}></td>
        <td className="chart-cell percent" style={calcProps.teamIdToFilter !== "devt" &&
          calcProps.teamIdToFilter !== "indet" ? {top: "-200px"} : {top: "0"}}>
          <div className="col">
            <div className="chart-wrapper">
              <Chart type="pie" data={chartDataPercent} options={chartOptionsPercent} />
            </div>
            <div className="legend-wrapper">
              <ul>
                <div className="col">{colItems1}</div>
                <div className="col">{colItems2}</div>
              </ul>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <th colSpan="2" className="default" style={styles.default}>
          ELD Technology
        </th>
        {calcProps.wd.map((_, index) => {
          return (
            <td key={index} className="greentd" style={styles.greentd}>
              {sumAllEldHoursByMonth(index).toString().replace(".", ",")}
            </td>
          );
        })}
        <td className="green" style={styles.green}></td>
      </tr>
      <tr>
        <th colSpan="2" className="default" style={styles.default}>
          Work organization
        </th>
        {calcProps.wd.map((_, index) => {
          return (
            <td key={index} className="greentd" style={styles.greentd}>
              {sumAllWOrgHoursByMonth(index).toString().replace(".", ",")}
            </td>
          );
        })}
        <td className="green" style={styles.green}></td>
      </tr>
      <tr>
        <th colSpan="2" className="default" style={styles.default}>
          Meetings
        </th>
        {calcProps.wd.map((_, index) => {
          return (
            <td key={index} className="greentd" style={styles.greentd}>
              {sumAllMeetingsHoursByMonth(index).toString().replace(".", ",")}
            </td>
          );
        })}
        <td className="green" style={styles.green}></td>
      </tr>
    </>
  );
};

export default TeamsPercentData;
