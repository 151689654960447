const initWidgetInfo = [
  {
    title: "Release TK32",
    vnr: "0.2.5.7",
    info: "Naprawiono opcję dodawania modułów w panelu admin. Od teraz można dodawać moduły w ścieżce Countries/Other.",
  },
];

const widgetReducer = (state = initWidgetInfo, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default widgetReducer;